/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    history: {
      all: [],
      filtered: [],
      selected: [],
    },
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    loading: {
      mainTable: false,
    },
    searchParams: {
      date: null,
      browserProfileId: null,
      userName: null,
      profileEvents: [],
      env: null,
      range: null,
      machineId: null,
      page: 1,
      limit: 10,
    },
  },
  getters: {
    getField,
    browserProfilesHistory: (state) => state.history,
    loading: (state) => state.loading,
    pagination: (state) => state.pagination,
  },
  mutations: {
    updateField,

    SET_ALL_HISTORY: (state, payload) => {
      state.history.all = payload;
    },

    SET_SELECTED: (state, payload) => {
      state.history.selected = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },
  },

  actions: {
    async loadHistory(context, payloadOptions = {}) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });
      const payload = { ...context.state.searchParams, ...payloadOptions };

      try {
        const request = '/admin/browser-profiles/histories';
        payload.profileEvents = payload.profileEvents.map((event) => event.value);

        if (payload.date) {
          payload.range = 'byDate';
          payload.date = payload.date?.join(',');
        }

        for (const key in payload) {
          if (payload[key] === null || payload[key] === '') {
            delete payload[key];
          } else if (typeof payload[key] === 'object') {
            if (Object.keys(payload[key]).length === 0) {
              delete payload[key];
            }
          }
        }

        const response = await this._vm.api.post(request, payload);
        console.log(response);
        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_HISTORY', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        const request = '/admin/browser-profiles/running-time';

        const response = await this._vm.api.post(request, { id: 0 });

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_HISTORY', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setPagination(context, payload) {
      const { response } = payload;
      const { search } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
        search,
      };

      await context.commit('SET_PAGINATION', pagination);
    },

    async clearSelected(context) {
      context.commit('SET_SELECTED', []);
    },
  },
};
