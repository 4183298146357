<template>
  <v-navigation-drawer
    permanent
    fixed
    width="200"
  >
    <div
      v-if="loading.profile"
      class="d-flex justify-center mt-4"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </div>
    <v-list nav>
      <v-list-item-group color="primary">
        <!-- АДМИНЫ -->
        <v-list-item
          v-if="profile.manageAdmins"
          :to="{path: '/admins'}"
        >
          <v-list-item-title>Админы</v-list-item-title>
        </v-list-item>

        <!-- АДМИНЫ КОНЕЦ -->
        <v-list-item
          v-if="profile.manageUsers"
          :to="{path: '/recurrent-payments'}"
        >
          <v-list-item-title>Рек. Платежи</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="profile.manageFinanceLogs"
          :to="{path: '/finance-logs'}"
        >
          <v-list-item-title>Фин.логи</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="profile.manageFinanceLogs"
          :to="{path: '/invoices'}"
        >
          <v-list-item-title>Инвойсы</v-list-item-title>
        </v-list-item>
        <!-- ПОЛЬЗОВАТЕЛИ -->
        <v-list-item
          v-if="profile.manageUsers"
          :to="{path: '/users'}"
        >
          <v-list-item-title>Пользователи</v-list-item-title>
        </v-list-item>
        <!-- ПОЛЬЗОВАТЕЛИ КОНЕЦ -->

        <!-- ПРОМОКОДЫ -->
        <v-list-item
          v-if="profile.viewPromocodes"
          :to="{path: '/promocodes'}"
        >
          <v-list-item-title>Промокоды</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="profile.setBranch"
          :to="{path: '/branches'}"
        >
          <v-list-item-title>Ветки</v-list-item-title>
        </v-list-item>
        <!-- ПРОМОКОДЫ КОНЕЦ -->

        <!-- РЕЛИЗЫ -->
        <!-- <v-list-item
          v-if="profile.manageReleases"
          :to="{path: '/releases'}"
        >
          <v-list-item-title>Релизы</v-list-item-title>
        </v-list-item> -->
        <!-- РЕЛИЗЫ КОНЕЦ -->

        <!-- БИЛДЫ БРАУЗЕРА -->
        <v-list-item
          v-if="profile.manageBrowserBuilds"
          :to="{path: '/browser_builds'}"
        >
          <v-list-item-title>Билды браузера</v-list-item-title>
        </v-list-item>
        <!-- БИЛДЫ БРАУЗЕРА КОНЕЦ -->

        <!-- БРАУЗЕРНЫЕ ПРОФИЛИ -->
        <v-list-item
          v-if="profile.manageBrowserProfiles"
          :to="{path: '/browser_profiles'}"
        >
          <v-list-item-title>Браузерные профили</v-list-item-title>
        </v-list-item>
        <!-- БРАУЗЕРНЫЕ ПРОФИЛИ КОНЕЦ -->

        <!-- БРАУЗЕРНЫЕ ПРОФИЛИ -->
        <v-list-item
          v-if="profile.manageBrowserProfiles"
          :to="{path: '/browser-profiles-history'}"
        >
          <v-list-item-title>История запуска БП</v-list-item-title>
        </v-list-item>
        <!-- БРАУЗЕРНЫЕ ПРОФИЛИ КОНЕЦ -->

        <!-- ЛОГИ -->
        <v-list-item
          v-if="profile.manageLogs"
          :to="{path: '/logs'}"
        >
          <v-list-item-title>Логи</v-list-item-title>
        </v-list-item>
        <!-- ЛОГИ КОНЕЦ -->

        <!-- ДОСТУПЫ -->
        <v-list-item
          v-if="profile.managePromoAccess"
          :to="{path: '/accesses'}"
        >
          <v-list-item-title>Доступы</v-list-item-title>
        </v-list-item>
        <!-- ДОСТУПЫ КОНЕЦ -->

        <!-- ИНВОЙСЫ COINBASE -->
        <v-list-item
          v-if="profile.manageCoinbase"
          :to="{path: '/coinbase'}"
        >
          <v-list-item-title>Инвойсы Coinbase</v-list-item-title>
        </v-list-item>
        <!-- ИНВОЙСЫ COINBASE КОНЕЦ -->

        <!-- Рефоводы -->
        <v-list-item
          v-if="profile.manageRefbringers"
          :to="{path: '/refbringers'}"
        >
          <v-list-item-title>Рефоводы</v-list-item-title>
        </v-list-item>
        <!-- Рефоводы КОНЕЦ -->

        <!-- ПРОДЛЕНИЕ ПОДПИСОК -->
        <v-list-item
          v-if="profile.addSubscriptionToEverybody"
          :to="{path: '/subscription-renewal'}"
        >
          <v-list-item-title>Продление подписок</v-list-item-title>
        </v-list-item>
        <!-- ПРОДЛЕНИЕ ПОДПИСОК КОНЕЦ -->
        <!-- КУРС ВАЛЮТ -->
        <v-list-item
          :to="{path: '/currencies'}"
        >
          <v-list-item-title>Курс валют</v-list-item-title>
        </v-list-item>
        <!-- КУРС ВАЛЮТК КОНЕЦ -->
        <!-- КАПЧА -->
        <v-list-item
          :to="{path: '/captcha'}"
        >
          <v-list-item-title>Капча</v-list-item-title>
        </v-list-item>
        <!-- ФЛАГИ -->
        <v-list-item
          v-if="profile.manageFeatureFlags"
          :to="{path: '/feature-flags'}"
        >
          <v-list-item-title>Флаги</v-list-item-title>
        </v-list-item>
        <!-- NOTIFICATIONS -->
        <v-list-item
          v-if="profile.manageNotifications"
          :to="{ path: '/notifications' }"
        >
          <v-list-item-title>Уведомление</v-list-item-title>
        </v-list-item>
        <!-- /ФЛАГИ -->
      </v-list-item-group>
    </v-list>

    <template #append>
      <!-- НАСТРОЙКИ -->
      <v-list-item :to="{ path: '/settings' }">
        <v-list-item-icon>
          <v-icon :color="$route.path.startsWith('/settings') ? 'primary' : ''">
            mdi-cog
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Настройки</v-list-item-title>
      </v-list-item>
      <!-- НАСТРОЙКИ КОНЕЦ -->

      <!-- USERNAME -->
      <v-list-item v-if="'displayName' in profile">
        <v-list-item-icon>
          <v-icon>
            mdi-account
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ profile.displayName }}</v-list-item-title>
      </v-list-item>

      <!-- ВЫЙТИ -->
      <v-list-item @click="logOut">
        <v-list-item-icon>
          <v-icon>
            mdi-logout
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Выйти</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import logOutMixin from '@/mixins/logOut';

export default {
  name: 'Sidebar',

  mixins: [
    logOutMixin,
  ],

  computed: {
    ...mapGetters({
      profile: 'main/profile',
      loading: 'main/loading',
    }),
  },
};
</script>
