import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import { getFingerprintData } from '@ilkkapeltola/thumbmarkjs-test';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@fortawesome/fontawesome-free/css/all.css';

Object.defineProperty(Vue.prototype, '$_', { value: _ });

const baseUrl = 'https://dolphin-anty-api.com';
const baseUrlAPIv2 = 'https://apiv2.dolphin-anty-api.com/api/v2';

async function initializeApp() {
  let fingerprint = '';
  try {
    const data = await getFingerprintData();
    const dataString = JSON.stringify(data, null, 2);
    const dataBuffer = new TextEncoder().encode(dataString);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    fingerprint = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  } catch (e) {
    console.warn('Error getting fingerprint', e);
  }

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('apiToken')}`,
      'X-Fingerprint': fingerprint,
    },
  });

  const apiv2 = axios.create({
    baseURL: baseUrlAPIv2,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('apiToken')}`,
      'X-Fingerprint': fingerprint,
    },
  });

  Vue.prototype.api = api;
  Vue.prototype.apiv2 = apiv2;
  Vue.prototype.baseUrl = baseUrl;
  Vue.prototype.baseUrlAPIv2 = baseUrlAPIv2;

  Vue.config.productionTip = false;

  // router.beforeEach((to, from, next) => {
  //   const profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null;
  //   if (profile) {
  //     if (to.name === 'Admins' && profile.manageAdmins) {
  //       next();
  //     } else if (to.name === 'Users' && profile.manageUsers) {

  //     }
  //   }
  // });

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
}

initializeApp();
